var description = "Binlerce Muhteşem Ödülde Heyecana Ortak Ol! Web Sitemize Gel, Çekiliş Biletini Al,  84 Yıllık Mili Piyango Güvencesiyle, Hayalini Kurduğun Her Şey e-Şans'da.";
var keywords = "piyango, eşya piyangosu, kazı kazan, manyın tarlası, zeplin, milli piayngo";
var paths = {
    home: {
        url: "/",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
        keywords: "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
    },
    login: {
        url: "/giris-yap",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    register: {
        url: "/uye-ol",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    resetPassword: {
        url: "/sifre-sifirla",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    verifyEmail: {
        url: "/eposta-dogrula",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    raffleTickets: {
        url: "/esya-piyangosu",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    products: {
        url: "/oduller",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    liveStream: {
        url: "/canli-izle",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    campaign: {
        url: "/kampanyalar",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    deposit: {
        url: "/para-yatir",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    withdraw: {
        url: "/para-cek",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    account: {
        url: "/hesabim",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    games: {
        url: "/dijital-oyunlar",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    newGames: {
        url: "/dijital-oyunlar/yeni-oyunlar",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    upcomingGames: {
        url: "/dijital-oyunlar/gelecek-oyunlar",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    notifications: {
        url: "/bildirimlerim",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    agreement: {
        url: "/sozlesmeler",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    transactions: {
        url: "/hesap-hareketleri",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    support: {
        url: "/yardim",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    aboutus: {
        url: "/hakkimizda",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    // branches: "/bayilerimiz",
    landing: {
        url: "/landing",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    redirectPage: {
        url: "/uye-ol-basarili",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    giftTicket: {
        url: "/hediye-biletler",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    qr: {
        url: "/qr",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    reportLine: {
        url: "/ihbar-hatti",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    cookieSettings: {
        url: "cerez-ayarlari",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    download: {
        url: "uygulama-indir",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    // games
    scratch: {
        url: "/kazi-kazan",
        title: "Online Kazı Kazan Oyna, Muhteşem Ödüller Kazan!",
        description: "Türkiye'nin yeni yasal şans oyunları platformu E-Şans'ta online Kazı Kazan oyna, E-Şans ile muhteşem ödüller kazanma şansını yakala!",
        keywords: "kazı kazan oyna onliine kazı kazan kazı kazan nasıl oynanır kazı kazan büyük ikramiye şans kartı kazı kazan kuralları kazı kazan para çıkma olasılığı kazı kazan ikramiyeleri kazı kazan en yüksek ikramiye",
    },
    scratchMyCards: {
        url: "/kazi-kazan/kartlarim",
        title: "Onlne Kazı Kazan Oyna, Muhteşem Ödüller Kazan!",
        description: "Türkiye'nin yeni yasal şans oyunları platformu E-Şans'ta online Kazı Kazan oyna, E-Şans ile muhteşem ödüller kazanma şansını yakala!",
        keywords: "kazı kazan oyna onliine kazı kazan kazı kazan nasıl oynanır kazı kazan büyük ikramiye şans kartı kazı kazan kuralları kazı kazan para çıkma olasılığı kazı kazan ikramiyeleri kazı kazan en yüksek ikramiye",
    },
    scartchRules: {
        url: "/kazi-kazan/kurallar",
        title: "Onlne Kazı Kazan Oyna, Muhteşem Ödüller Kazan!",
        description: "Türkiye'nin yeni yasal şans oyunları platformu E-Şans'ta online Kazı Kazan oyna, E-Şans ile muhteşem ödüller kazanma şansını yakala!",
        keywords: "kazı kazan oyna onliine kazı kazan kazı kazan nasıl oynanır kazı kazan büyük ikramiye şans kartı kazı kazan kuralları kazı kazan para çıkma olasılığı kazı kazan ikramiyeleri kazı kazan en yüksek ikramiye",
    },
    superBingo: {
        url: "/super-tombala",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    zeplin: {
        url: "/zeplin",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    fireballs: {
        url: "/ates-toplari",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    fruitTowes: {
        url: "/meyve-kulesi",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    turboMines: {
        url: "/isiltili-taslar",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    crashX: {
        url: "/galaksi-turu",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    turboPlinko: {
        url: "/super-plinko",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    hamsta: {
        url: "/kunduzun-macerasi",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    superWheel: {
        url: "/super-carkifelek",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    rockPaperScissors: {
        url: "/tas-kagit-makas",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
};
export default paths;
