import paths from "../paths";
export var env;
if (window.location.host.includes("test") ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("0.0.0.0")) {
    env = {
        accounting_api: "https://test-api.e-sans.com.tr",
        cdn_host: "https://test-api.e-sans.com.tr",
        accounting_wss: "wss://ws-test.e-sans.com.tr/stream",
        pageTitle: "e-Şans",
        // web_link: 'https://test.e-sans.com.tr',
        // accounting_api: 'https://api.e-sans.com.tr',
        // cdn_host: 'https://api.e-sans.com.tr',
        // pageTitle: 'e-Şans',
    };
}
else {
    env = {
        accounting_api: "https://api.e-sans.com.tr",
        cdn_host: "https://api.e-sans.com.tr",
        accounting_wss: "wss://ws.e-sans.com.tr/stream",
        web_link: "https://e-sans.com.tr",
        pageTitle: "e-Şans",
    };
}
export var news = [];
export var loginModalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        maxWidth: "calc(100vw - 80px)",
        width: "460px",
        // height: '420px',
        maxHeight: "calc(100% - 100px)",
        padding: "1.5rem",
        transform: "translate(-50%, -50%)",
        background: "var(--bs-white)",
    },
};
export var ccModalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        maxWidth: "calc(100vw - 80px)",
        width: "460px",
        height: "480px",
        maxHeight: "calc(100% - 100px)",
        padding: "1.5rem",
        transform: "translate(-50%, -50%)",
        background: "var(--bs-white)",
    },
};
export var passwordModalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        maxWidth: "calc(100vw - 80px)",
        width: "460px",
        padding: "1.5rem",
        maxHeight: "calc(100vh - 100px)",
        transform: "translate(-50%, -50%)",
        background: "var(--bs-white)",
    },
};
export var modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        maxWidth: "calc(100vw - 80px)",
        width: "920px",
        padding: "0px",
        maxHeight: "calc(100vh - 100px)",
        transform: "translate(-50%, -50%)",
        background: "var(--bs-white)",
    },
};
export var iframeModalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        maxWidth: "calc(100vw - 80px)",
        width: "920px",
        padding: "0px",
        maxHeight: "calc(100% - 50px)",
        height: "calc(100% - 50px)",
        transform: "translate(-50%, -50%)",
        background: "var(--bs-white)",
    },
};
export var redirectionErrorCodes = ["TC_ID_EXISTS", "EMAIL_EXISTS", "MOBILE_EXISTS"];
export var wsFunctionTypes = [
    "checkAuth",
    "accountApproved",
    "newNotification",
    "updateSlide",
    "updatePos",
    "updateBalance",
    "deleteNotification",
    "balanceError",
    "updateTicket",
    "orderStatus",
];
export var warninLogStyle = "color: black; background: yellow; font-size: 12px; font-weight:bold";
export var successLogStyle = "color: white; background: green; font-size: 12px; font-weight:bold";
export var errorLogStyle = "color: white; background: red; font-size: 12px; font-weight:bold";
export var listCartHiddenPages = [paths.login.url, paths.register.url, paths.landing.url];
export var listGamePages = [
    paths.scratch.url,
    paths.scratchMyCards.url,
    paths.scartchRules.url,
    paths.superBingo.url,
    paths.zeplin.url,
    paths.fireballs.url,
    paths.fruitTowes.url,
    paths.superWheel.url,
    paths.rockPaperScissors.url,
    paths.turboMines.url,
    paths.crashX.url,
    paths.turboPlinko.url,
    paths.hamsta.url,
];
export var agreements = {};
export var siteSettings = {};
export var longDays = {
    0: "Pazar",
    1: "Pazartesi",
    2: "Salı",
    3: "Çarşamba",
    4: "Perşembe",
    5: "Cuma",
    6: "Cumartesi",
};
export var shortDays = {
    0: "Pzr",
    1: "Pts",
    2: "Sal",
    3: "Çar",
    4: "Per",
    5: "Cum",
    6: "Cmt",
};
export var months = [
    { val: "01", name: "Ocak" },
    { val: "02", name: "Şubat" },
    { val: "03", name: "Mart" },
    { val: "04", name: "Nisan" },
    { val: "05", name: "Mayıs" },
    { val: "06", name: "Haziran" },
    { val: "07", name: "Temmuz" },
    { val: "08", name: "Ağustos" },
    { val: "09", name: "Eylül" },
    { val: "10", name: "Ekim" },
    { val: "11", name: "Kasım" },
    { val: "12", name: "Aralık" },
];
export var supportTicketStatus = {
    0: "Beklemede",
    1: "Değerlendiriliyor",
    2: "Farklı birime devredildi",
    9: "Sonuçlandı",
};
export var drawTags = {
    0: "",
    1: "Yeni",
    2: "İkinci El",
    3: "Çok Satan",
};
export var categoryEmojiMapping = {
    23: "💻",
    20: "🎈",
    17: "🎟",
    14: "🧹",
    11: "📺",
    8: "🏍",
    5: "🚗",
    2: "📱",
};
