var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./cookies.css";
import { Storage } from "../../services/localstorege.service";
import { useNavigate } from "react-router";
import { api } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import { wssProvider } from "../../services/websocket.service";
import { auth } from "../../store/auth.store";
import paths from "../../paths";
export default function Cookies() {
    var cookiesData = Storage.get("cookies-policy");
    var navigate = useNavigate();
    function admit() {
        var element = document.getElementById("cookies");
        element.style.display = "none";
        Storage.set("cookies-policy", true);
        if (auth.member) {
            api
                .start("post", env.accounting_api + "/member/api/update-account-info/", { "functional": true, "targeting_cookies": true, "mandatory": true }, true)
                .then(function (res) {
                wssProvider.checkauth();
            })
                .finally(function () {
            });
        }
    }
    return cookiesData ? null : (_jsx("div", __assign({ className: "footer-cookies-bar", id: "cookies" }, { children: _jsxs("div", __assign({ className: "container footer-cookies-container" }, { children: [_jsx("p", { children: "\u0130nternet sayfam\u0131zda yasal d\u00FCzenlemelere uygun olarak sayfam\u0131z\u0131 i\u015Flevsel tutmak, kullan\u0131c\u0131 memnuniyetini art\u0131rmak ve size \u00F6zel pazarlama ve reklamc\u0131l\u0131k faaliyetleri ger\u00E7ekle\u015Ftirebilmek i\u00E7in \u00E7erezler kullan\u0131lmaktad\u0131r. \u00C7erez Politikam\u0131z\u0131 inceleyerek detayl\u0131 bilgi alabilirsiniz." }), _jsxs("div", __assign({ className: "row mt-2" }, { children: [_jsx("div", __assign({ className: "col-6 text-end" }, { children: _jsx("button", __assign({ onClick: function () {
                                    navigate(paths.cookieSettings.url);
                                }, className: "btn btn-small btn-outline-primary " }, { children: "\u00C7erez Tercihleri" })) })), _jsx("div", __assign({ className: "col-6 " }, { children: _jsx("button", __assign({ onClick: function () { return admit(); }, className: "btn btn-small btn-primary " }, { children: "Kabul Ediyorum" })) }))] }))] })) })));
}
