var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { env } from "../../constants/global.constants";
import { dateFormat, dateTimeFormat, loadingAnimation, loginRequired, useForceUpdate, } from "../../services/misc.functions";
import { useEffect, useState } from "react";
import { api } from "../../services/http.service";
import "./my.raffle.orders.css";
import { openRaffleDetail } from "../../services/game.service";
import { raffleDraws, raffleDrawSubject } from "../../store/games.store";
import { Raffle } from "../../models/game.model";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { modalServiceSubject } from "../../services/modal.service";
import { auth } from "../../store/auth.store";
import { LoginRequired } from "../../shared/auth/login.required";
import { isMobile } from "../../App";
import paths from "../../paths";
var OrderStatusMap = {
    0: {
        class: "warning",
        label: "Oynanıyor",
    },
    1: {
        class: "warning",
        label: "Beklemede",
    },
    2: {
        class: "success",
        label: "Kazanan",
    },
    3: {
        class: "danger",
        label: "Kaybetti",
    },
};
var myRaffleListPage = 0;
var xhrMetaData = {};
export function MyRaffleOrders() {
    var _this = this;
    var _a;
    var _b = useState([]), dataList = _b[0], setDataList = _b[1];
    var _c = useState(0), dataFilterTab = _c[0], setDataFilterTab = _c[1];
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    var pathname = useLocation().pathname;
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        loadData(0);
    }, []);
    useEffect(function () {
        loadData(0);
    }, [pathname, dataFilterTab]);
    var loadData = function (forcePage) {
        if (forcePage === void 0) { forcePage = null; }
        return __awaiter(_this, void 0, void 0, function () {
            var pageN, payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loadingAnimation(true);
                        setLoading(true);
                        pageN = forcePage !== null ? forcePage : myRaffleListPage;
                        if (pageN === 0 && dataList && dataList.length > 0) {
                            setDataList([]);
                        }
                        payload = {
                            page: pageN,
                            status: dataFilterTab === 0 ? "0,1" : dataFilterTab,
                            type: "get",
                        };
                        return [4 /*yield*/, api
                                .start("post", env.accounting_api + "/somos/api/esya-piyangosu/kuponlarim/", payload, true)
                                .then(function (res) {
                                // console.log(res)
                                loadingAnimation(false);
                                setLoading(false);
                                var tmp = [];
                                if (res && res.status) {
                                    if (pageN === 0 && dataList && dataList.length > 0) {
                                        dataList.splice(0, dataList.length);
                                        tmp.push.apply(tmp, res.data);
                                    }
                                    else {
                                        if (dataList === null) {
                                            tmp.push.apply(tmp, res.data);
                                        }
                                        else {
                                            tmp = __spreadArray([], dataList, true);
                                            tmp.push.apply(tmp, res.data);
                                        }
                                    }
                                }
                                if (res && res.metadata) {
                                    xhrMetaData = res.metadata;
                                    var id_datalist_loadmore = document.getElementById("id_datalist_loadmore");
                                    if (res.metadata.count === 0 || res.metadata.count <= tmp.length) {
                                        id_datalist_loadmore.style.display = "none";
                                    }
                                    else {
                                        id_datalist_loadmore.style.display = "unset";
                                    }
                                }
                                setDataList(tmp);
                                forceUpdate();
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    var loadMore = function (e) {
        e.target.disabled = true;
        loadData().finally(function () {
            e.target.disabled = false;
        });
    };
    var showRaffleTickets = function (el) {
        var payload = {
            draw_id: String(el.draw_id),
            type: "get_draw",
        };
        api
            .start("post", env.accounting_api + "/somos/api/esya-piyangosu/kuponlarim/", payload, true)
            .then(function (res) {
            if (res && res.status) {
                var groupByCategory_1 = res.data.reduce(function (group, product) {
                    var _a;
                    var create_date = product.create_date;
                    group[create_date.substring(0, 16)] =
                        (_a = group[create_date.substring(0, 16)]) !== null && _a !== void 0 ? _a : [];
                    group[create_date.substring(0, 16)].push(product);
                    return group;
                }, {});
                modalServiceSubject.next({
                    title: "Biletlerim - ".concat(el.draw.title_abbreviated),
                    content: "<p>\n              <div class=\"row mx-0\">\n                  ".concat(Object.keys(groupByCategory_1)
                        .map(function (itm) {
                        return "<div class=\"container-fluid\">\n                <div class=\"row group-header\">\n                Al\u0131m Zaman\u0131: ".concat(dateTimeFormat(itm), "\n                </div>\n                <div class=\"row\">\n                ").concat(groupByCategory_1[itm]
                            .map(function (c) {
                            return "<div class= \"col-3 p-1 text-center\"> <div class=\"border  rounded-2 ".concat(c.status === 2
                                ? "bg-success-subtle  border-success"
                                : c.status === 3
                                    ? "bg-danger-subtle border-danger"
                                    : "bg-secondary-subtle border-secondary", "\" >").concat(c.approve_code, " </div></div > ");
                        })
                            .join(""), "</div>\n                \n                </div>");
                    })
                        .join(""), "\n              </div>\n            </p>"),
                    confirm: {
                        cancel: {
                            label: "Kapat",
                            class: "btn btn-secondary w-100",
                        },
                    },
                });
            }
        });
    };
    if (!((_a = auth.member) === null || _a === void 0 ? void 0 : _a.id)) {
        loginRequired(navigate);
        return _jsx(LoginRequired, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col-lg-12 col-md-12" }, { children: _jsxs("div", __assign({ className: "draw-filter-wrapper" }, { children: [!isMobile && _jsx("div", __assign({ className: "float-start" }, { children: _jsxs("h4", { children: ["E\u015Fya Piyangosu Biletlerim", _jsxs("span", { children: [xhrMetaData === null || xhrMetaData === void 0 ? void 0 : xhrMetaData.count, " \u00E7ekili\u015F, ", dataList.length, " adet g\u00F6sterilen."] })] }) })), _jsxs("div", __assign({ className: "float-lg-end" }, { children: [_jsx("button", __assign({ className: "btn btn-sm me-1 btn-outline-primary  ".concat(dataFilterTab === 0 && "active"), onClick: function () {
                                            setDataFilterTab(0);
                                        } }, { children: "Bekleyen" })), _jsx("button", __assign({ className: "btn btn-sm me-1 btn-outline-primary  ".concat(dataFilterTab === 2 && "active", " "), onClick: function () {
                                            setDataFilterTab(2);
                                        } }, { children: "Kazanan" })), _jsx("button", __assign({ className: "btn btn-sm me-1 btn-outline-primary ".concat(dataFilterTab === 3 && "active", " "), onClick: function () {
                                            setDataFilterTab(3);
                                        } }, { children: "Kaybeden" }))] }))] })) })) })), _jsx("div", __assign({ className: "my-raffle-order-item" }, { children: dataList.map(function (el, key) {
                    var _a, _b, _c, _d, _e, _f;
                    if (Number(el.lost_count) + Number(el.won_count) === 0) {
                        el.status = 1;
                    }
                    else if (Number(el.won_count) > 0) {
                        el.status = 2;
                    }
                    else if (Number(el.pending_count) > 0) {
                        el.status = 0;
                    }
                    else if (Number(el.lost_count) > 0) {
                        el.status = 3;
                    }
                    return !isMobile ? (_jsx("div", __assign({ className: "row item py-2" }, { children: el.draw && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "col-auto" }, { children: _jsx("img", { className: "img-thumbnail", src: "".concat(env.cdn_host, "/media/").concat((_a = el.draw) === null || _a === void 0 ? void 0 : _a.photo) }) })), _jsxs("div", __assign({ className: "col-4" }, { children: [_jsx("h2", { children: (_b = el.draw) === null || _b === void 0 ? void 0 : _b.title_abbreviated }), _jsxs("span", __assign({ className: "d-block" }, { children: ["\u00C7ekili\u015F Tarihi: ", dateFormat((_c = el.draw) === null || _c === void 0 ? void 0 : _c.draw_date)] })), _jsxs("span", __assign({ className: "d-block" }, { children: ["\u00C7ekili\u015F No: ", el.draw.no] })), _jsx("span", __assign({ className: "d-block link-primary pointer", onClick: function () {
                                                var draw = raffleDraws.find(function (x) { return x.no === el.draw.no; });
                                                if (draw) {
                                                    openRaffleDetail(draw);
                                                }
                                                else {
                                                    el.draw.detail_photo = el.draw.detail_photo.replace("/media/", "");
                                                    el.status = el.status === true ? 1 : 0;
                                                    raffleDrawSubject.next({
                                                        action: "history",
                                                        draw: new Raffle(el.draw),
                                                    });
                                                }
                                            } }, { children: "\u00C7ekili\u015F Detaylar\u0131" }))] })), _jsxs("div", __assign({ className: "col-4 d-flex justify-content-between align-items-center" }, { children: [_jsxs("div", { children: [_jsxs("span", __assign({ className: "d-block" }, { children: ["Bilet \u00FCcreti: ", Number(el.draw.ticket_price).toFixed(2), " \u20BA"] })), _jsxs("span", __assign({ className: "d-block" }, { children: ["Al\u0131nan bilet:", el.status === 0 ? (_jsx(_Fragment, { children: " Sat\u0131n alma devam ediyor" })) : (_jsxs(_Fragment, { children: [" ", el.status === 3 ? el.lost_count : el.status === 2 ? el.won_count : el.played_count, " adet"] }))] })), _jsxs("span", __assign({ className: "d-block fw-bold" }, { children: ["Toplam Tutar:", el.status === 0 ? (_jsx(_Fragment, { children: " Sat\u0131n alma devam ediyor" })) : (_jsxs(_Fragment, { children: [" ", (Number(el.draw.ticket_price) *
                                                                    Number(el.status === 3 ? el.lost_count : el.status === 2 ? el.won_count : el.played_count)).toFixed(2), "\u20BA"] }))] })), _jsx("span", __assign({ className: "d-block link-primary pointer", onClick: function () {
                                                        showRaffleTickets(el);
                                                    } }, { children: "Biletlerimi G\u00F6r" }))] }), _jsx("div", __assign({ className: "status ".concat(OrderStatusMap[el.status].class) }, { children: OrderStatusMap[el.status].label }))] }))] })) }), "item-my-raffle-order-".concat(key))) : (_jsx("div", __assign({ className: "row item py-2" }, { children: el.draw && (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "col-4 column-center" }, { children: [_jsx("img", { className: "img-thumbnail", src: "".concat(env.cdn_host, "/media/").concat((_d = el.draw) === null || _d === void 0 ? void 0 : _d.photo) }), _jsx("br", {}), _jsx("h2", __assign({ className: "text-center" }, { children: (_e = el.draw) === null || _e === void 0 ? void 0 : _e.title_abbreviated })), _jsx("span", __assign({ className: "d-block link-primary pointer text-center", onClick: function () {
                                                var draw = raffleDraws.find(function (x) { return x.no === el.draw.no; });
                                                if (draw) {
                                                    openRaffleDetail(draw);
                                                }
                                                else {
                                                    el.draw.detail_photo = el.draw.detail_photo.replace("/media/", "");
                                                    el.status = el.status === true ? 1 : 0;
                                                    raffleDrawSubject.next({
                                                        action: "history",
                                                        draw: new Raffle(el.draw),
                                                    });
                                                }
                                            } }, { children: "\u00C7ekili\u015F Detaylar\u0131" }))] })), _jsxs("div", __assign({ className: "col-8" }, { children: [_jsx("div", __assign({ className: "d-flex justify-content-start" }, { children: _jsx("div", __assign({ className: "status ".concat(OrderStatusMap[el.status].class) }, { children: _jsx("p", __assign({ className: "m-1" }, { children: OrderStatusMap[el.status].label })) })) })), _jsxs("span", __assign({ className: "d-block", style: { fontSize: "14px" } }, { children: ["\u00C7ekili\u015F Tarihi:", _jsx("span", __assign({ style: { color: "blue" } }, { children: dateFormat((_f = el.draw) === null || _f === void 0 ? void 0 : _f.draw_date) }))] })), _jsxs("span", __assign({ className: "d-block", style: { fontSize: "14px" } }, { children: ["\u00C7ekili\u015F No:", _jsx("span", __assign({ style: { color: "blue" } }, { children: el.draw.no }))] })), _jsxs("div", __assign({ className: "raffle-order-mobile" }, { children: [_jsxs("span", __assign({ className: "d-block", style: { fontSize: "14px" } }, { children: ["Bilet \u00FCcreti: ", Number(el.draw.ticket_price).toFixed(2), "\u20BA"] })), _jsxs("span", __assign({ className: "d-block", style: { fontSize: "14px" } }, { children: ["Al\u0131nan bilet:", el.status === 0 ? (_jsx(_Fragment, { children: "Sat\u0131n alma devam ediyor" })) : (_jsxs(_Fragment, { children: [el.played_count, " adet"] }))] })), _jsx("hr", {}), _jsxs("span", __assign({ className: "d-block fw-bold", style: { fontSize: "15px" } }, { children: ["Toplam Tutar: ", _jsx("br", {}), el.status === 0 ? (_jsx(_Fragment, { children: "Sat\u0131n alma devam ediyor" })) : (_jsxs(_Fragment, { children: [(Number(el.draw.ticket_price) *
                                                                    Number(el.played_count)).toFixed(2), "\u20BA"] }))] }))] })), _jsx("span", __assign({ className: "d-block link-primary pointer text-end", onClick: function () {
                                                showRaffleTickets(el);
                                            } }, { children: "Biletlerimi G\u00F6r" }))] }))] })) }), "item-my-raffle-order-".concat(key)));
                }) })), dataList && dataList.length === 0 && !loading && (_jsxs(_Fragment, { children: [dataFilterTab === 0 && (_jsx("div", __assign({ className: "text-center py-5" }, { children: "Hen\u00FCz \u00E7ekili\u015Fe kat\u0131lmam\u0131\u015Fs\u0131n\u0131z \uD83E\uDD28 Hemen al\u0131n, \u015Fans\u0131n\u0131z\u0131 deneyin." }))), dataFilterTab === 2 && (_jsx("div", __assign({ className: "text-center py-5" }, { children: "Hen\u00FCz kazanan biletiniz yok. Hemen al\u0131n, \u015Fans\u0131n\u0131z\u0131 deneyin." }))), dataFilterTab === 3 && (_jsx("div", __assign({ className: "text-center py-5" }, { children: "Hen\u00FCz kaybeden biletiniz yok. Hemen al\u0131n, \u015Fans\u0131n\u0131z\u0131 deneyin." }))), _jsx("div", __assign({ className: "d-flex justify-content-center" }, { children: _jsx("button", __assign({ className: "mx-auto btn btn-primary w-50 mt-5", onClick: function () {
                                navigate(paths.raffleTickets.url);
                            }, id: "btn_submit" }, { children: "\u00C7ekili\u015Fler" })) }))] })), _jsxs("div", __assign({ className: "text-center" }, { children: [_jsx("div", { id: "id_loaderAnimation", className: "loader" }), _jsx("button", __assign({ id: "id_datalist_loadmore", onClick: loadMore, type: "button", className: "btn rounded-pill btn-outline-primary" }, { children: "Devam\u0131n\u0131 Y\u00FCkle" }))] }))] }));
}
