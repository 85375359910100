var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./deposit.css";
import iconCard from "../../assets/icons/feather/credit-card.svg";
import { api } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import { useEffect, useState } from "react";
import { modalServiceSubject } from "../../services/modal.service";
import { copyMemberNumber } from "../../services/misc.functions";
import copyIcon from "../../assets/icons/feather/copy.svg";
import { isMobile } from "../../App";
export function Deposit() {
    var _this = this;
    var _a = useState([]), bankAccounts = _a[0], setBankAccounts = _a[1];
    useEffect(function () {
        getBankAccounts();
    }, []);
    var getBankAccounts = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api
                        .start("get", env.accounting_api + "/accounting/api/list-bank-account/", null, false)
                        .then(function (res) {
                        // console.log(res)
                        setBankAccounts(res.data);
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "card rounded-4 mt-sm-3" }, { children: _jsxs("div", __assign({ className: "card-body deposit-page" }, { children: [_jsxs("h2", { children: [_jsx("img", { src: iconCard, alt: "icon", className: "me-2" }), " Banka Hesab\u0131n\u0131zdan T\u00FCrk Liras\u0131 Yat\u0131r\u0131n"] }), _jsx("p", __assign({ className: "ms-4" }, { children: "Kendi ad\u0131n\u0131za ait vadesiz hesab\u0131n\u0131z ile a\u015Fa\u011F\u0131daki banka hesab\u0131na FAST ile 7/24 veya EFT saatlerinde T\u00FCrk Liras\u0131 yat\u0131rabilirsiniz." })), _jsxs("div", { children: ["Hesap Sahibi:", _jsx("span", __assign({ className: "d-inline-block ", onClick: function () {
                                        return copyMemberNumber("106 Dijital Hizmetler ve Şans Oyunları Anonim Şirketi", "Hesap sahibi kopyalandı!");
                                    } }, { children: _jsx("img", { className: "copy-icon", src: copyIcon }) })), _jsx("br", {}), _jsx("b", __assign({ style: { letterSpacing: "0.001px", whiteSpace: "normal" } }, { children: "106 Dijital Hizmetler ve \u015Eans Oyunlar\u0131 Anonim \u015Eirketi" }))] }), _jsx("hr", {}), _jsxs("div", __assign({ style: { whiteSpace: "normal" } }, { children: ["IBAN Numaras\u0131:", _jsx("span", __assign({ className: "d-inline-block", onClick: function () {
                                        return copyMemberNumber("TR970001200975100010261765", "IBAN numarası kopyalandı!");
                                    } }, { children: _jsx("img", { className: "copy-icon", src: copyIcon }) })), _jsx("br", {}), _jsx("b", __assign({ style: { whiteSpace: "normal" } }, { children: "TR97 0001 2009 7510 0010 2617 65" }))] })), _jsx("div", __assign({ className: "alert alert-warning p-2 mt-3 " }, { children: _jsx("p", __assign({ className: "white-space-normal fs-7" }, { children: "Ad\u0131n\u0131za kay\u0131tl\u0131 ki\u015Fisel hesab\u0131n\u0131zdan para yat\u0131rd\u0131\u011F\u0131n\u0131za emin olun." })) })), _jsxs("ul", __assign({ className: "text-secondary" }, { children: [_jsx("li", __assign({ className: "mb-3 white-space-normal" }, { children: "Banka hesaplar\u0131n\u0131zdan FAST ile 20.000 TRY\u2019ye kadar olan tutarlarda 7/24, FAST limitini a\u015Fan tutarlar i\u00E7in ise EFT ile i\u015F g\u00FCnlerinde 9.15-16.45 saatleri i\u00E7erisinde T\u00FCrk Liras\u0131 yat\u0131rabilirsiniz. EFT saatleri d\u0131\u015F\u0131nda yapaca\u011F\u0131n\u0131z i\u015Flemler, takip eden ilk i\u015F g\u00FCn\u00FCnde bakiyenize y\u00FCklenecektir." })), _jsx("li", __assign({ className: "mb-3 .white-space-normal" }, { children: "Banka EFT komisyonu kesebilir. EFT / FAST komisyonlar\u0131 bankadan bankaya de\u011Fi\u015Fkenlik g\u00F6sterebilir." }))] }))] })) })), _jsxs("div", __assign({ className: "card bank-cost-table-card rounded-4 mt-4" }, { children: [_jsx("div", __assign({ className: "card-header bank-cost-table-header" }, { children: _jsx("h2", { children: "Anla\u015Fmal\u0131 Bankalar" }) })), _jsx("div", __assign({ className: "card-body bank-cost-table-body deposit-page" }, { children: isMobile ?
                            _jsx(_Fragment, { children: bankAccounts &&
                                    bankAccounts.map(function (el, key) {
                                        return (_jsx("table", __assign({ className: "table bank-cost-table table-borderless table-striped" }, { children: _jsxs("tbody", { children: [_jsx("tr", { children: _jsx("td", __assign({ className: "text-center bank-name bank-cost-table-td", colSpan: 2 }, { children: _jsx("img", { className: "w-90", src: env.cdn_host + el.bank.logo }) })) }), _jsxs("tr", { children: [_jsx("th", __assign({ className: "text-center" }, { children: "\u0130nternet Bankac\u0131l\u0131\u011F\u0131" })), _jsx("td", __assign({ className: "text-center bank-cost-table-td" }, { children: "\u2705" }))] }), _jsxs("tr", { children: [_jsx("th", __assign({ className: "text-center" }, { children: "Mobil Bankac\u0131l\u0131k" })), _jsx("td", __assign({ className: "text-center bank-cost-table-td" }, { children: "\u2705" }))] }), _jsxs("tr", { children: [_jsx("th", __assign({ className: "text-center" }, { children: "EFT" })), _jsx("td", __assign({ className: "text-center bank-cost-table-td" }, { children: "\u2705" }))] }), _jsxs("tr", { children: [_jsx("th", __assign({ className: "text-center" }, { children: "FAST" })), _jsx("td", __assign({ className: "text-center bank-cost-table-td" }, { children: "\u2705" }))] }), _jsxs("tr", { children: [_jsx("th", __assign({ className: "text-center" }, { children: "Telefon Bankac\u0131l\u0131\u011F\u0131" })), _jsx("td", __assign({ className: "text-center bank-cost-table-td" }, { children: "\u2705" }))] })] }) }), key + el.id));
                                    }) })
                            : _jsxs("table", __assign({ className: "table bank-cost-table table-borderless table-striped" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { className: "bank-cost-table-th" }), _jsx("th", { className: "bank-cost-table-th" }), _jsx("th", __assign({ className: "text-center" }, { children: "\u0130nternet Bankac\u0131l\u0131\u011F\u0131" })), _jsx("th", __assign({ className: "text-center" }, { children: "Mobil Bankac\u0131l\u0131k" })), _jsx("th", __assign({ className: "text-center" }, { children: "EFT" })), _jsx("th", __assign({ className: "text-center" }, { children: "FAST" })), _jsx("th", __assign({ className: "text-center" }, { children: "Telefon Bankac\u0131l\u0131\u011F\u0131" }))] }) }), _jsx("tbody", { children: bankAccounts &&
                                            bankAccounts.map(function (el, key) {
                                                return (_jsxs("tr", __assign({ className: "pointer", onClick: function () {
                                                        modalServiceSubject.next({
                                                            title: el.bank.name,
                                                            style: { overflow: "hidden" },
                                                            class: "bank-modal",
                                                            content: "<iframe src=\"https://cdn.e-sans.com.tr/banks/".concat(el.bank.code, "/").concat(el.bank.code, ".html?fromweb=true\"></iframe>"),
                                                            confirm: {
                                                                cancel: {
                                                                    label: "Kapat",
                                                                    class: "btn-secondary w-100",
                                                                },
                                                            },
                                                        });
                                                    } }, { children: [_jsx("td", __assign({ className: "text-center" }, { children: _jsx("img", { src: env.cdn_host + el.bank.logo, className: "img-thumbnail", alt: el.bank.name }) })), _jsx("td", __assign({ className: "text-left bank-name bank-cost-table-td fw-bold" }, { children: el.bank.name })), _jsx("td", __assign({ className: "text-center bank-cost-table-td" }, { children: "\u2705" })), _jsx("td", __assign({ className: "text-center bank-cost-table-td" }, { children: "\u2705" })), _jsx("td", __assign({ className: "text-center bank-cost-table-td" }, { children: "\u2705" })), _jsx("td", __assign({ className: "text-center bank-cost-table-td" }, { children: "\u2705" })), _jsx("td", __assign({ className: "text-center bank-cost-table-td" }, { children: "\u2705" }))] }), "bank-accounts-".concat(key)));
                                            }) })] })) }))] })), _jsx("div", { className: "card-body deposit-page" })] }));
}
