var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import "./cart.dropdown.css";
import { cartStore, cartToggleSubject } from "../../store/cart.store";
import iconShoppingCartBasket from "../../assets/icons/feather/shopping-cart-basket.svg";
import { openRaffleDetail } from "../../services/game.service";
import { clearCart, orderComplete, removeFromCart, updateCart } from "../../services/cart.service";
import { auth, authSubject, loginModalSubject } from "../../store/auth.store";
import { CouponCodeWidget } from "./coupon.code";
import { modalServiceSubject } from "../../services/modal.service";
import iconTrashWhite from "../../assets/icons/feather/trash-2.svg";
import iconDown from "../../assets/icons/feather/chevron-down.svg";
import { CreditCardForm } from "../../pages/deposit/credit.card.form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { gtmBeginCheckout, gtmViewCart, useForceUpdate } from "../../services/misc.functions";
import { raffleDraws } from "../../store/games.store";
import { isMobile } from "../../App";
import CartMobileDropdown from "./cart.mobile.dropdown";
import { updateOrderSubject } from "../../store/misc.store";
import paths from "../../paths";
var cartItemCountDropDown = 0;
var cartTotalPriceDropDown = 0;
var cartTotalPriceWithDiscountDropDown = 0;
var statLabelDropDown = {
    danger: "Başarısız",
    warning: "Kısmi Oynandı",
    success: "Oynandı",
};
export function CartDropdown() {
    var _a;
    var pathname = useLocation().pathname;
    var _b = useState(false), visible = _b[0], setVisible = _b[1];
    var _c = useState(0), cartStep = _c[0], setCartStep = _c[1];
    var _d = useState(false), showCCForm = _d[0], setShowCCForm = _d[1];
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    var hideCart = function () {
        cartToggleSubject.next({ action: "visibleToggleCart", show: false });
    };
    useEffect(function () {
        var auth_subscriber = authSubject.subscribe(function (res) {
            forceUpdate();
        });
        return function () {
            auth_subscriber.unsubscribe();
        };
    }, []);
    useEffect(function () {
        var updateOrder_subscriber = updateOrderSubject.subscribe(function (res) {
            setCartStep(0);
        });
        return function () {
            updateOrder_subscriber.unsubscribe();
        };
    }, []);
    var reCalc = function () {
        cartItemCountDropDown =
            cartStore.items.length > 0
                ? cartStore.items
                    .map(function (el) { return el.quantity; })
                    .reduce(function (a, b) {
                    return Number(a) + Number(b);
                })
                : 0;
        cartTotalPriceDropDown =
            cartStore.items.length > 0
                ? cartStore.items
                    .map(function (el) { return Number(el.quantity) * Number(el.item.ticket_price); })
                    .reduce(function (a, b) {
                    return Number(a) + Number(b);
                })
                : 0;
        cartTotalPriceWithDiscountDropDown =
            Number(cartTotalPriceDropDown) -
                (cartStore.couponCode && cartStore.couponCode.minCartCond <= cartTotalPriceDropDown
                    ? Number(cartStore.couponCode.amount)
                    : 0);
        if (cartTotalPriceWithDiscountDropDown < 0) {
            cartTotalPriceWithDiscountDropDown = 0;
        }
        cartStore.total = cartTotalPriceWithDiscountDropDown;
    };
    var setPointUsage = function () {
        cartStore.pointUsage = !cartStore.pointUsage;
        cartToggleSubject.next({ action: "update" });
        forceUpdate();
    };
    var completeThePurchase = function () {
        var doneButton = document.getElementById("doneButton");
        doneButton.disabled = true;
        if (!cartStore.pointUsage && cartTotalPriceWithDiscountDropDown > auth.member.balance_debit) {
            if (cartTotalPriceWithDiscountDropDown < Number(auth.member.balance_debit) + Number(auth.member.balance_point)) {
                modalServiceSubject.next({
                    case: "danger",
                    title: "Bakiyeniz Yetersiz",
                    width: "400px",
                    content: "<p class=\"text-center\">\n                    \u015EP Kullanabilir veya Kredi Kart\u0131 ile \u00F6demeye devam edebilirsiniz.\n                   </p>\n                   ",
                    confirm: {
                        sure: {
                            label: "ŞP Kullan",
                            class: "btn btn-success",
                            action: function () {
                                setPointUsage();
                                completeThePurchase();
                            },
                        },
                        cancel: {
                            label: "Kredi Kartı İle Öde",
                            class: "btn-warning w-100",
                            action: function () {
                                setShowCCForm(true);
                            },
                        },
                    },
                });
            }
            else if (cartStore.couponCode) {
                modalServiceSubject.next({
                    case: "danger",
                    title: "Bakiyeniz Yetersiz",
                    width: "400px",
                    content: "<p class=\"text-center\">\n                   Hesab\u0131n\u0131za bakiye y\u00FCkleyebilirsiniz.\n                   </p>\n                   ",
                    confirm: {
                        cancel: {
                            label: "Diğer Ödeme Yöntemleri",
                            class: "btn-info  w-100",
                            action: function () {
                                navigate(paths.deposit.url);
                            },
                        },
                    },
                });
            }
            else {
                modalServiceSubject.next({
                    case: "danger",
                    title: "Bakiyeniz Yetersiz",
                    width: "400px",
                    content: "<p class=\"text-center\">\n                    Kredi Kart\u0131 ile \u00F6demeye devam edebilir veya hesab\u0131n\u0131za bakiye y\u00FCkleyebilirsiniz.\n                   </p>\n                   ",
                    confirm: {
                        sure: {
                            label: "Kredi Kartı İle Öde",
                            class: "btn-warning w-100",
                            action: function () {
                                if (cartStore.pointUsage) {
                                    modalServiceSubject.next({
                                        case: "warning",
                                        title: "",
                                        width: "400px",
                                        content: "<p class=\"text-center\">\n                    Kredi Kart\u0131 \u00F6demelerinde \u015Eans Puan bakiyesi kullan\u0131lamaz.\n                   </p>\n                   ",
                                        confirm: {
                                            sure: {
                                                label: "Kredi Kartı İle Öde",
                                                class: "btn-warning w-100",
                                                action: function () {
                                                    setPointUsage();
                                                    setShowCCForm(true);
                                                },
                                            },
                                            cancel: {
                                                label: "Diğer Ödeme Yöntemleri",
                                                class: "btn-info  w-100",
                                                action: function () {
                                                    navigate(paths.deposit.url);
                                                },
                                            },
                                        },
                                    });
                                }
                                else {
                                    setShowCCForm(true);
                                }
                            },
                        },
                        cancel: {
                            label: "Diğer Ödeme Yöntemleri",
                            class: "btn-info  w-100",
                            action: function () {
                                navigate(paths.deposit.url);
                            },
                        },
                    },
                });
            }
            doneButton.disabled = false;
        }
        else if (cartStore.pointUsage &&
            cartTotalPriceWithDiscountDropDown > Number(auth.member.balance_debit) + Number(auth.member.balance_point) &&
            !cartStore.couponCode) {
            modalServiceSubject.next({
                case: "danger",
                title: "Bakiyeniz Yetersiz",
                width: "400px",
                content: "<p class=\"text-center\">\n                    Kredi Kart\u0131 ile \u00F6demeye devam edebilir veya hesab\u0131n\u0131za bakiye y\u00FCkleyebilirsiniz.\n                   </p>\n                   ",
                confirm: {
                    sure: {
                        label: "Kredi Kartı İle Öde",
                        class: "btn-warning w-100",
                        action: function () {
                            if (cartStore.pointUsage) {
                                modalServiceSubject.next({
                                    case: "warning",
                                    title: "",
                                    width: "400px",
                                    content: "<p class=\"text-center\">\n                    Kredi Kart\u0131 \u00F6demelerinde \u015Eans Puan bakiyesi kullan\u0131lamaz.\n                   </p>\n                   ",
                                    confirm: {
                                        sure: {
                                            label: "Kredi Kartı İle Öde",
                                            class: "btn-warning w-100",
                                            action: function () {
                                                setPointUsage();
                                                setShowCCForm(true);
                                            },
                                        },
                                        cancel: {
                                            label: "Diğer Ödeme Yöntemleri",
                                            class: "btn-info  w-100",
                                            action: function () {
                                                navigate(paths.deposit.url);
                                            },
                                        },
                                    },
                                });
                            }
                            else {
                                setShowCCForm(true);
                            }
                        },
                    },
                    cancel: {
                        label: "Diğer Ödeme Yöntemleri",
                        class: "btn-info  w-100",
                        action: function () {
                            navigate(paths.deposit.url);
                        },
                    },
                },
            });
            doneButton.disabled = false;
        }
        else if (cartStore.pointUsage &&
            cartTotalPriceWithDiscountDropDown > Number(auth.member.balance_debit) + Number(auth.member.balance_point) &&
            cartStore.couponCode) {
            modalServiceSubject.next({
                case: "danger",
                title: "Bakiyeniz Yetersiz",
                width: "400px",
                content: "<p class=\"text-center\">\n                    Hesab\u0131n\u0131za bakiye y\u00FCkleyebilirsiniz.\n                   </p>\n                   ",
                confirm: {
                    cancel: {
                        label: "Diğer Ödeme Yöntemleri",
                        class: "btn-info  w-100",
                        action: function () {
                            navigate(paths.deposit.url);
                        },
                    },
                },
            });
            doneButton.disabled = false;
        }
        else {
            orderComplete()
                .then(function (res) {
                setCartStep(0);
                if (res.status === false && res.error === "re-login") {
                    modalServiceSubject.next({
                        case: "danger",
                        width: "400px",
                        title: "Oturumunuz Sonlandı",
                        content: "<p class=\"text-center\">L\u00FCtfen tekrar giri\u015F yap\u0131n\u0131z</p>",
                        confirm: {
                            sure: {
                                label: "Giriş Yap",
                                class: "btn-danger w-100",
                                action: function () {
                                    loginModalSubject.next("show");
                                },
                            },
                            cancel: {
                                label: "İptal",
                                class: "btn-secondary w-100",
                            },
                        },
                    });
                }
                if (res.status === false && res.error === "timeout") {
                    modalServiceSubject.next({
                        case: "danger",
                        width: "400px",
                        title: "İşleminiz Zaman Aşımına Uğradı",
                        content: "<p class=\"text-center\">Bu durum, ba\u015Far\u0131l\u0131 yada ba\u015Far\u0131s\u0131z anlam\u0131na gelmez. L\u00FCtfen bilet sat\u0131n alma durumunuzu biletlerim sayfas\u0131nda tekrar kontrol ediniz.</p>",
                        confirm: {
                            sure: {
                                label: "Biletlerim Sayfasına Git",
                                class: "btn-secondary w-100",
                                action: function () {
                                    navigate(paths.raffleTickets.url + "/biletlerim");
                                },
                            },
                            cancel: {
                                label: "Kapat",
                                class: "btn-secondary w-100",
                            },
                        },
                    });
                }
                if (res.status === false && res.error === "ticket_count_error") {
                    modalServiceSubject.next({
                        case: "danger",
                        width: "400px",
                        title: "Hata!",
                        content: "<p class=\"text-center\">".concat(res.message, "</p>"),
                        confirm: {
                            cancel: {
                                label: "Kapat",
                                class: "btn-secondary w-100",
                            },
                        },
                    });
                }
                if (res.status === true) {
                    clearCart();
                    modalServiceSubject.next({
                        case: "success",
                        title: "Ödemeniz Başarılı",
                        width: "400px",
                        content: "<p class=\"text-center\">\n                    Biletleriniz ba\u015Far\u0131yla sat\u0131n al\u0131nm\u0131\u015Ft\u0131r. Bilet numaralar\u0131n\u0131z\u0131 ve detaylar\u0131n\u0131 g\u00F6rmek i\u00E7in Biletlerim sayfas\u0131na gidebilirsiniz.\n                   </p>",
                        confirm: {
                            sure: {
                                label: "Biletlerimi Gör",
                                class: "btn-success w-100",
                                action: function () {
                                    navigate(paths.raffleTickets.url + "/biletlerim");
                                },
                            },
                            cancel: {
                                label: "Yeni Bilet Al",
                                class: "btn-secondary w-100",
                                action: function () {
                                    // get current path
                                    var currentPath = window.location.pathname;
                                    if (currentPath == paths.raffleTickets.url) {
                                        // if current path is raffle tickets, then we need to reload the page
                                        window.location.reload();
                                    }
                                    else {
                                        // if current path is not raffle tickets, then we need to navigate to raffle tickets
                                        navigate(paths.raffleTickets.url);
                                    }
                                },
                            },
                        },
                    });
                }
                if (res.status === false) {
                    if (res.success_count > 0) {
                        clearCart();
                    }
                    modalServiceSubject.next({
                        case: res.error_count > 0 && res.success_count > 0 ? "warning" : "danger",
                        title: res.success_count === 0 ? "Biletler Alınamadı" : "Bazı Biletler Alınamadı",
                        width: "400px",
                        content: "<p class=\"text-center\">\n                    Bilet al\u0131m i\u015Flemi ba\u015Far\u0131s\u0131z olan bilet tutar\u0131 e-\u015Eans hesab\u0131n\u0131za iade edildi.\n                   </p>\n                   <div class=\"prize-request mx-n10px px-4 rounded-4\" style=\"height: 300px; overflow: hidden; overflow-y: auto\">\n                   ".concat(Object.entries(res.data)
                            .map(function (_a, k) {
                            var d = _a[0], v = _a[1];
                            var dr = raffleDraws.find(function (el) { return el.no === Number(d); });
                            // let stat = v.data.success_count > 0 && v.data.error_count === 0 ? 'success': (v.data.success_count > 0 ? 'warning' : 'danger');
                            return "<div class=\"row item px-1 py-3 pb-0 rounded-2\">\n                        <div class=\"col-auto\">\n                            <img class=\"img-thumbnail\" src=\"".concat(dr.photo, "\" />\n                        </div>\n                        <div class=\"col-8\">\n                            <h3 class=\"nowrap\">").concat(dr.title_abbreviated, "</h3>\n                            ").concat(v.data.success_count > 0
                                ? "<span class=\"d-block text-success fw-bold\">Ba\u015Far\u0131l\u0131 al\u0131nan bilet: ".concat(v.data.success_count, " adet</span>")
                                : "", "\n                            ").concat(v.data.error_count > 0
                                ? "<span class=\"d-block text-danger fw-bold\">Hatal\u0131 bilet: ".concat(v.data.error_count, " adet</span><span class=\"d-block text-danger\">").concat(v.data.message, "</span>")
                                : "", "\n                        </div>\n                      </div>");
                        })
                            .join(""), " </div>"),
                        confirm: {
                            sure: res.success_count > 0
                                ? {
                                    label: "Biletlerimi Gör",
                                    class: "btn-warning w-100",
                                    action: function () {
                                        navigate(paths.raffleTickets.url + "/biletlerim");
                                    },
                                }
                                : null,
                            cancel: res.success_count === 0
                                ? {
                                    label: "Tamam",
                                    class: "btn-danger  w-100",
                                }
                                : {
                                    label: "Yeni Bilet Al",
                                    class: "btn-default  w-100",
                                    action: function () {
                                        // get current path
                                        var currentPath = window.location.pathname;
                                        if (currentPath == paths.raffleTickets.url) {
                                            // if current path is raffle tickets, then we need to reload the page
                                            window.location.reload();
                                        }
                                        else {
                                            // if current path is not raffle tickets, then we need to navigate to raffle tickets
                                            navigate(paths.raffleTickets.url);
                                        }
                                    },
                                },
                        },
                    });
                }
            })
                .finally(function () {
                doneButton.disabled = false;
            });
        }
    };
    useEffect(function () {
        var cartToggleSubject_subscriber = cartToggleSubject.subscribe(function (res) {
            if (res && res.action === "visibleToggleCart") {
                setVisible(res.show);
                reCalc();
                return null;
            }
            if (res && res.action === "couponCode") {
                setTimeout(function () {
                    cartStore.couponCode = res.data;
                    reCalc();
                    forceUpdate();
                }, 100);
            }
            else {
                reCalc();
                forceUpdate();
            }
        });
        return function () {
            cartToggleSubject_subscriber.unsubscribe();
            document.body.style.overflow = "unset";
        };
    }, []);
    useEffect(function () {
        if (!isMobile) {
            setVisible(false);
        }
    }, [pathname]);
    setTimeout(function () {
        gtmViewCart(cartStore.items, "Çekiliş");
        var mainWrapper = document.getElementById("mainWrapper");
        if (visible) {
            if (mainWrapper) {
                mainWrapper.classList.add("col-lg-9");
            }
        }
        else {
            if (mainWrapper) {
                mainWrapper.classList.remove("col-lg-9");
            }
        }
    }, 20);
    if (!visible) {
        return _jsx(_Fragment, {});
    }
    if (visible && isMobile) {
        document.body.style.overflow = "hidden";
    }
    else {
        document.body.style.overflow = "unset";
    }
    return (_jsxs("div", __assign({ className: "cart-dropdown" }, { children: [_jsxs("div", __assign({ className: "cart-dropdown-widget" }, { children: [isMobile ? (_jsx(CartMobileDropdown, { cartItemCountDropDown: cartItemCountDropDown, hideCart: hideCart, 
                        // setCartStep={setCartStep}
                        cartTotalPriceDropDown: cartTotalPriceDropDown, cartTotalPriceWithDiscountDropDown: cartTotalPriceWithDiscountDropDown, 
                        // completeThePurchase={completeThePurchase}
                        setShowCCForm: setShowCCForm, 
                        // setPointUsage={setPointUsage}
                        cartStep: cartStep })) : (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "head" }, { children: _jsx("h2", __assign({ className: "text-center" }, { children: "\u00C7ekili\u015F Sepetim" })) })), _jsx("div", { className: "clipper" }), _jsxs("div", __assign({ className: "body" }, { children: [cartStore.items.length === 0 ? (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "no-data-prag" }, { children: [_jsx("img", { src: iconShoppingCartBasket }), _jsx("p", { children: " Sepetinizde Bilet Bulunmamaktad\u0131r." }), isMobile && (_jsx("button", __assign({ className: "btn w-80 btn-primary fw-bolder", onClick: function () { return navigate(paths.raffleTickets.url); }, id: "btn_submit" }, { children: "\u00C7ekili\u015Fleri \u0130ncele" })))] })) })) : (_jsx(_Fragment, {})), cartStore.items.map(function (item, key) { return (_jsxs("div", __assign({ className: "item" }, { children: [_jsx("img", { src: item.item.photo, onClick: function () {
                                                    openRaffleDetail(item.item);
                                                } }), _jsxs("div", __assign({ className: "item-detail" }, { children: [_jsx("h3", __assign({ title: item.item.title_abbreviated, className: "nowrap", onClick: function () {
                                                            openRaffleDetail(item.item);
                                                        } }, { children: item.item.title_abbreviated })), _jsxs("p", __assign({ className: "nowrap", onClick: function () {
                                                            openRaffleDetail(item.item);
                                                        } }, { children: ["Bilet fiyat\u0131: ", item.item.ticket_price, "\u20BA"] })), _jsx("div", __assign({ style: { height: "33px", display: "flex" } }, { children: _jsxs("div", __assign({ className: "input-group" }, { children: [_jsx("i", __assign({ onClick: function () {
                                                                        if (item && (item === null || item === void 0 ? void 0 : item.quantity) > 1) {
                                                                            updateCart(item, key, Number(item === null || item === void 0 ? void 0 : item.quantity) - 1);
                                                                        }
                                                                    } }, { children: "-" })), _jsx("input", { type: "number", className: "no-arrows", min: 1, onChange: function (e) {
                                                                        updateCart(item, key, e.target.value);
                                                                    }, onBlur: function () {
                                                                        if (item.quantity === "" || item.quantity === "0") {
                                                                            updateCart(item, key, 1);
                                                                        }
                                                                    }, value: item.quantity || "" }), _jsx("i", __assign({ onClick: function () {
                                                                        if (item && (item === null || item === void 0 ? void 0 : item.quantity) < 100) {
                                                                            updateCart(item, key, Number(item.quantity) + 1);
                                                                        }
                                                                    } }, { children: "+" })), _jsx("span", __assign({ className: "input-group-text" }, { children: _jsx("img", { className: "trash", src: iconTrashWhite, onClick: function () {
                                                                            removeFromCart(item, key);
                                                                        } }) }))] })) }))] })), _jsx("div", { className: "clearfix" })] }), "cartwidget-item-".concat(key))); })] })), _jsx("div", __assign({ className: "options" }, { children: _jsxs("div", __assign({ className: "bgWhite" }, { children: [_jsx("h3", { children: "Toplam" }), _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-6" }, { children: "Bilet Adedi:" })), _jsxs("div", __assign({ className: "col-6 fw-bold text-end" }, { children: [cartItemCountDropDown, " adet"] }))] })), _jsxs("div", __assign({ className: "row" }, { children: [_jsx("label", __assign({ className: "col-6" }, { children: "Toplam Tutar:" })), _jsxs("label", __assign({ className: "col-6 fw-bold text-end" }, { children: [cartTotalPriceDropDown.toFixed(2), "\u20BA"] }))] })), ((_a = auth.member) === null || _a === void 0 ? void 0 : _a.id) && cartItemCountDropDown > 0 && (_jsxs("div", __assign({ className: "row coupon-code" }, { children: [_jsx("label", __assign({ className: "col-6" }, { children: "\u0130ndirim Kuponu:" })), _jsx("label", __assign({ className: "col-6 px-0 fw-bold text-end" }, { children: _jsx(CouponCodeWidget, {}) })), cartStore.couponCode && cartStore.couponCode.minCartCond > cartTotalPriceDropDown ? (_jsx(_Fragment, { children: _jsxs("p", __assign({ className: "text-danger text-end", style: { fontSize: 13 } }, { children: ["Bu kupon", Number(cartStore.couponCode.minCartCond).toFixed(2), "\u20BA ve \u00FCzeri sepette ge\u00E7erlidir."] })) })) : (_jsx(_Fragment, {}))] })))] })) })), _jsxs("div", __assign({ className: "foot" }, { children: [_jsxs("button", __assign({ disabled: cartItemCountDropDown === 0, className: "btn w-80 btn-buy fw-bolder txt-white", onClick: function () {
                                            gtmBeginCheckout();
                                            if (auth.member && auth.member.id) {
                                                setCartStep(1);
                                            }
                                            else {
                                                if (isMobile) {
                                                    navigate(paths.landing.url);
                                                }
                                                else {
                                                    modalServiceSubject.next({
                                                        class: "success",
                                                        title: "Üye Girişi",
                                                        content: "<p>Sat\u0131nalma yapabilmeniz i\u00E7in \u00F6nce oturum a\u00E7man\u0131z gerekli. \u00DCye de\u011Filseniz hemen \u00FCye olabilir yada giri\u015F yaparak al\u0131\u015Fveri\u015Finizi tamamlaya bilirsiniz.</p>",
                                                        confirm: {
                                                            sure: {
                                                                label: "Giriş Yap",
                                                                class: "btn btn-success",
                                                                action: function () {
                                                                    navigate(paths.login.url);
                                                                },
                                                            },
                                                            cancel: {
                                                                label: "Üye Ol",
                                                                class: "btn btn-info",
                                                                action: function () {
                                                                    navigate(paths.register.url);
                                                                },
                                                            },
                                                        },
                                                    });
                                                }
                                            }
                                        } }, { children: ["Al\u0131\u015Fveri\u015Fi Tamamla", _jsx("span", { className: "price" })] })), _jsx("button", __assign({ disabled: cartItemCountDropDown === 0, className: "btn btn-danger", style: {
                                            width: "calc(20% - 5px)",
                                            marginLeft: 5,
                                        }, onClick: function () {
                                            clearCart();
                                        } }, { children: _jsx("img", { src: iconTrashWhite, style: { width: 20, marginTop: -2 } }) }))] }))] })), _jsxs("div", __assign({ className: "order-steps payment-methods " + (cartStep === 1 && "active") }, { children: [_jsxs("div", __assign({ className: "cutEffect gray" }, { children: [_jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {})] })), _jsx("img", { src: iconDown, className: "close", onClick: function () {
                                    setCartStep(0);
                                } }), _jsxs("h2", __assign({ className: "mt-3" }, { children: [cartItemCountDropDown, " Bilet tutar\u0131", " ", cartStore.couponCode && (_jsxs("span", __assign({ className: "text-decoration-line-through fs-6" }, { children: [cartTotalPriceDropDown.toFixed(2), "\u20BA"] }))), " ", cartTotalPriceWithDiscountDropDown.toFixed(2), "\u20BA"] })), _jsxs("p", { children: ["Harcama sonras\u0131nda ", cartTotalPriceWithDiscountDropDown.toFixed(2), cartStore.pointUsage ? "ŞP" : "₺", " bakiyenizden d\u00FC\u015Fecektir."] }), cartStore.couponCode && (_jsxs("p", __assign({ className: "fst-italic fw-bold" }, { children: [cartStore.couponCode.amount.toFixed(2), "\u20BA kupon indirimi uygulanm\u0131\u015Ft\u0131r."] }))), auth.authentication && auth.member && auth.member.id && auth.member.balance_point > 0 && (_jsxs("div", __assign({ className: "form-check" }, { children: [_jsx("input", { className: "form-check-input", type: "checkbox", value: cartStore.pointUsage, checked: cartStore.pointUsage, id: "id_use_ps_point", onChange: function () {
                                            setPointUsage();
                                        } }), _jsxs("label", __assign({ className: "form-check-label", htmlFor: "id_use_ps_point" }, { children: ["\u015EP Puan Harca (Kullan\u0131labilir: ", auth.member.balance_point, " \u015EP)"] }))] }))), _jsx("span", __assign({ className: "text-warning my-2", style: { fontSize: "12px" } }, { children: "Sat\u0131lan biletler iadesi ger\u00E7ekle\u015Ftirilemez" })), _jsxs("div", __assign({ className: "footer-actions" }, { children: [_jsx("div", { className: "wave" }), _jsxs("div", __assign({ className: "body-row row" }, { children: [_jsx("div", __assign({ className: "col-12" }, { children: _jsx("button", __assign({ id: "doneButton", className: "btn w-100 btn-success", onClick: function () {
                                                        completeThePurchase();
                                                    } }, { children: "\u00D6demeyi Tamamla" })) })), _jsx("div", __assign({ className: "col-12" }, { children: !cartStore.couponCode && (_jsx("button", __assign({ type: "button", onClick: function () {
                                                        if (cartStore.pointUsage) {
                                                            modalServiceSubject.next({
                                                                case: "warning",
                                                                title: "",
                                                                width: "400px",
                                                                content: "<p class=\"text-center\">\n                                                    Kredi Kart\u0131 \u00F6demelerinde \u015Eans Puan bakiyesi kullan\u0131lamaz.\n                                                   </p>\n                                                   ",
                                                                confirm: {
                                                                    sure: {
                                                                        label: "Kredi Kartı İle Öde",
                                                                        class: "btn-warning w-100",
                                                                        action: function () {
                                                                            setPointUsage();
                                                                            setShowCCForm(true);
                                                                        },
                                                                    },
                                                                    cancel: {
                                                                        label: "Diğer Ödeme Yöntemleri",
                                                                        class: "btn-info  w-100",
                                                                        action: function () {
                                                                            navigate(paths.deposit.url);
                                                                        },
                                                                    },
                                                                },
                                                            });
                                                        }
                                                        else {
                                                            setShowCCForm(true);
                                                        }
                                                    }, className: "btn w-100 btn-secondary me-2" }, { children: "Kredi Kart\u0131 ile \u00D6de" }))) }))] }))] }))] }))] })), _jsx(CreditCardForm, { showCCForm: showCCForm, setShowCCForm: setShowCCForm })] })));
}
